import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

//components
import Card from '../../../sections/Blog/Card';
import BlogSeo from '../../../components/BlogSeo';
import RootLayout from '../../../layouts/RootLayout';

const Container = styled.section`
    max-width: 1280px;
    margin: 0 auto;
    padding: 90px 44px;

    @media screen and (max-width: 500px) {
        padding: 40px 20px;
    }
`;

const CardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 44px;

    @media screen and (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`;

export const query = graphql`
    query HomePageQuery($status: String) {
        cmsV1 {
            posts(where: { status: { equals: $status } }, orderBy: { publishDate: desc }) {
                id
                title
                summary
                pinned
                status
                slug
                heroImage {
                    url
                    srcSet
                }
                tags {
                    id
                    name
                    rgbColor
                }
            }
        }
    }
`;

function BlogPage({ data }) {
    return (
        <>
            <BlogSeo
                title="Authenticate Blog"
                description="The latest news, updates, and resources from Authenticate. Learn more about identity verification, background checks, and more."
                pageType="all blogs"
            />
            <RootLayout>
                <Container id="resources">
                    <CardGrid>
                        {data.cmsV1.posts.map((post) => {
                            return (
                                <Card
                                    title={post.title}
                                    summary={post.summary}
                                    id={post.id}
                                    tags={post.tags}
                                    first={post.pinned}
                                    blogImage={post.heroImage.url}
                                    srcSet={post.heroImage.srcSet}
                                    key={post.id}
                                    slug={post.slug}
                                />
                            );
                        })}
                    </CardGrid>
                </Container>
            </RootLayout>
        </>
    );
}

export default BlogPage;
